/* eslint-disable camelcase, react-hooks/rules-of-hooks */
import { useQuery, UseQueryResult, useMutation } from 'react-query';
import axios from '@utils/axios';
import queryClient from '../queryClient';
import { DealList, DealListItem, DealPostType } from '../types';
import { useGetUserData } from './useGetUserData';

interface ResponseData {
  dealListItems: Array<{
    id: number;
    ASIN: string;
    createdAt: string;
    updatedAt: string;
    listId: number;
  }>;
  dealListSections: Array<{
    id: number;
    name: string;
    isWeekly: boolean;
    description: string;
    createdAt: string;
    updatedAt: string;
  }>;
  dealLists: Array<{
    activeTimeFrame: string;
    id: number;
    createdAt: string;
    updatedAt: string;
    description: string;
    name: string;
    numLiveDeals: number;
    sectionId: number;
  }>;
}

export function useGetDealListAll(): UseQueryResult<ResponseData> {
  const { data: userData } = useGetUserData();

  const queryFn = async (): Promise<ResponseData> => {
    const result = await axios.get<{
      data: ResponseData;
    }>(`/api/dashboard/deal-list-sections`);
    return result.data;
  };

  return useQuery<ResponseData, Error>(`deal-list-sections`, () => queryFn(), {
    enabled: !!userData?.isAdmin,
    onError: () => {
      console.error('Something went wrong while fetching the site metrics');
    }
  });
}

export function useCopyDealList() {
  interface RequestAddData {
    dealListFromId: number;
    dealListToId: number;
  }

  const mutationFn = async ({
    dealListFromId,
    dealListToId
  }: RequestAddData): Promise<null> => {
    return axios.post(`/api/dashboard/add-deal-list-to-deal-list`, {
      dealListToId,
      dealListFromId
    });
  };

  return useMutation(mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries('deal-list-sections');
    },
    onError: () => {
      console.error('error adding deal list to deal list');
    }
  });
}

const queryDealLists = async (): Promise<DealList[]> => {
  const result = await axios.get<{
    data: DealList[];
  }>(`/api/dashboard/get-deal-lists`);
  return result.data;
};

export function useGetDealLists(): UseQueryResult<DealList[]> {
  return useQuery<DealList[], Error>(`deal-lists`, () => queryDealLists(), {
    enabled: false,
    onError: () => {
      console.error('Something went wrong while fetching the deal lists');
    }
  });
}

export function useGetDealListsEnabled(): UseQueryResult<DealList[]> {
  return useQuery<DealList[], Error>(`deal-lists`, () => queryDealLists(), {
    onError: () => {
      console.error('Something went wrong while fetching the deal lists');
    }
  });
}

export function useGetDealListForItem(
  ASIN: string
): UseQueryResult<DealListItem[]> {
  const queryFn = async (ASIN: string): Promise<DealListItem[]> => {
    const result = await axios.get<{
      data: DealListItem[];
    }>(`/api/dashboard/get-deal-lists-for-item/${ASIN}`);
    return result.data;
  };

  return useQuery<DealListItem[], Error>(
    `deal-lists-${ASIN}`,
    () => queryFn(ASIN),
    {
      enabled: false,
      onError: () => {
        console.error(
          `Something went wrong while fetching the deal lists for the item ${ASIN}`
        );
      }
    }
  );
}

export function useGetAllDealsForDealListId(
  dealListId: number
): UseQueryResult<DealPostType[]> {
  const queryFn = async (dealListId: number): Promise<DealPostType[]> => {
    const result = await axios.get<{ data: DealPostType[] }>(
      `/api/dashboard/deal-lists/all-deals-for-deal-list/${dealListId}`
    );
    return result.data;
  };

  return useQuery<DealPostType[], Error>(
    `deal-lists-all-${dealListId}`,
    () => queryFn(dealListId),
    {
      onError: () => {
        console.error(
          `Something went wrong while fetching all deals for deal list ${dealListId}`
        );
      }
    }
  );
}

export function useChangeDealLists() {
  interface RequestData {
    ASIN: string;
    dealListIds: number[];
  }

  const mutationFn = async ({
    ASIN,
    dealListIds
  }: RequestData): Promise<null> => {
    return axios.post(`/api/dashboard/change-deal-lists`, {
      ASIN,
      dealListIds
    });
  };

  return useMutation(mutationFn, {
    onSuccess: (_data, req) => {
      queryClient.refetchQueries(`deal-lists-${req.ASIN}`);
    },
    onError: () => {
      console.error('error changing deal lists for item');
    }
  });
}

export function useAddDealList() {
  const mutationFn = async (dealList: {
    name: string;
    isWeekly: boolean;
    sectionId: number;
    description: string;
  }): Promise<null> => {
    return axios.post(`/api/dashboard/deal-list`, dealList);
  };

  return useMutation(mutationFn, {
    onSuccess: () => {
      queryClient.refetchQueries(`deal-lists`);
    },
    onError: () => {
      console.error('error adding deal list');
    }
  });
}

export function useUpdateDealList() {
  interface mutationData {
    id: number;
    dealList: {
      name: string;
      isWeekly: boolean;
      sectionId: number;
      description: string;
    };
  }
  const mutationFn = async ({ id, dealList }: mutationData): Promise<null> => {
    return axios.post(`/api/dashboard/update-deal-list/${id}`, dealList);
  };

  return useMutation(mutationFn, {
    onSuccess: () => {
      queryClient.refetchQueries(`deal-lists`);
    },
    onError: () => {
      console.error('error updating deal list');
    }
  });
}

export function useDeleteDealList() {
  const mutationFn = async (id: number): Promise<null> => {
    return axios.delete(`/api/dashboard/delete-deal-list/${id}`);
  };

  return useMutation(mutationFn, {
    onSuccess: () => {
      queryClient.refetchQueries(`deal-lists`);
    },
    onError: () => {
      console.error('error deleting deal list');
    }
  });
}

export function useUpdateDealListSection() {
  interface mutationData {
    id: number;
    dealList: {
      name: string;
      isWeekly: boolean;
      sectionId: number;
      description: string;
    };
  }
  const mutationFn = async ({ id, dealList }: mutationData): Promise<null> => {
    return axios.post(
      `/api/dashboard/update-deal-list-section/${id}`,
      dealList
    );
  };

  return useMutation(mutationFn, {
    onSuccess: () => {
      queryClient.refetchQueries(`deal-list-sections`);
    },
    onError: () => {
      console.error('error updating deal list section');
    }
  });
}

export function useAddDealListSection() {
  const mutationFn = async (dealListSection: {
    name: string;
    isWeekly: boolean;
    description: string;
  }): Promise<null> => {
    return axios.post(`/api/dashboard/deal-list-section`, dealListSection);
  };

  return useMutation(mutationFn, {
    onSuccess: () => {
      queryClient.refetchQueries(`deal-list-sections`);
    },
    onError: () => {
      console.error('error adding deal list section');
    }
  });
}

export function useDeleteDealListSection() {
  const mutationFn = async (id: number): Promise<null> => {
    return axios.delete(`/api/dashboard/deal-list-section/${id}`);
  };

  return useMutation(mutationFn, {
    onSuccess: () => {
      queryClient.refetchQueries(`deal-list-sections`);
    },
    onError: () => {
      console.error('error deleting deal list section');
    }
  });
}
