/* eslint-disable camelcase, react-hooks/rules-of-hooks */
import { useQuery, UseQueryResult } from 'react-query';
import axios from '@utils/axios';
import { SentNewsletter } from '@api/database/models/sentNewsletter';

export function useGetSentNewsletters(
  mailingListId?: string
): UseQueryResult<SentNewsletter[]> {
  const queryFn = async (): Promise<SentNewsletter[]> => {
    const result = await axios.get<{ data: SentNewsletter[] }>(
      `/api/sent-newsletters/get/${mailingListId}`
    );
    return result.data;
  };

  return useQuery<SentNewsletter[], Error>(
    `sent-newsletters-${mailingListId}`,
    () => queryFn(),
    {
      onError: () => {
        console.error(
          'Something went wrong while fetching the sent newsletters'
        );
      }
    }
  );
}
