/* eslint-disable camelcase, react-hooks/rules-of-hooks */
import { useMutation } from 'react-query';
import axios from '@utils/axios';
import { useSnackbar } from '@hooks/useSnackbar';

export function useGetAIInternalLinkText() {
  interface RequestData {
    pageDealDescription?: string;
    pageDealName: string;
  }

  const mutationFn = async ({
    pageDealName,
    pageDealDescription
  }: RequestData): Promise<{
    suggestion: string;
  }> => {
    const response = await axios.post<{
      data: {
        suggestion: string;
      };
    }>(`/api/dashboard/ai-internal-link-text`, {
      pageDealName,
      pageDealDescription
    });

    return response.data;
  };

  return useMutation(mutationFn, {
    onError: () => {
      console.log('Error getting AI internal link text');
    }
  });
}

export function useGetAIDescription() {
  interface RequestData {
    pageDealDescription?: string;
    pageDealName: string;
    id?: number;
  }

  const mutationFn = async ({
    pageDealName,
    pageDealDescription,
    id
  }: RequestData): Promise<{
    suggestion: string;
  }> => {
    const response = await axios.post<{
      data: {
        suggestion: string;
      };
    }>(`/api/dashboard/ai-description`, {
      pageDealName,
      pageDealDescription,
      id
    });

    return response.data;
  };

  return useMutation(mutationFn, {
    onError: () => {
      console.log('Error getting AI');
    }
  });
}

export function useGetAIFix() {
  interface RequestData {
    prompt?: string;
    text: string;
  }

  const mutationFn = async ({
    prompt,
    text
  }: RequestData): Promise<{
    suggestion: string;
  }> => {
    const response = await axios.post<{
      data: {
        suggestion: string;
      };
    }>(`/api/dashboard/ai-fix`, {
      prompt,
      text
    });

    return response.data;
  };

  return useMutation(mutationFn, {
    onError: () => {
      console.log('Error getting AI fix');
    }
  });
}

export function useGetAIPageDealTitle() {
  const { showMessage } = useSnackbar();
  interface RequestData {
    title: string;
  }

  const mutationFn = async ({
    title
  }: RequestData): Promise<{
    suggestions: string[];
  }> => {
    const response = await axios.post<{
      data: {
        suggestions: string[];
      };
    }>(`/api/dashboard/ai/get-page-deal-title`, {
      title
    });

    return response.data;
  };

  return useMutation(mutationFn, {
    onError: (err) => {
      showMessage(`Error getting AI suggestions ${err}`);
    }
  });
}

export function useGetAIPostText() {
  interface RequestData {
    ASIN: string;
    prompt?: string;
    text?: string;
  }

  const mutationFn = async ({
    ASIN,
    prompt,
    text
  }: RequestData): Promise<{
    suggestion: string;
  }> => {
    const response = await axios.post<{
      data: {
        suggestion: string;
      };
    }>(`/api/dashboard/ai-post-text`, {
      ASIN,
      prompt,
      text
    });

    return response.data;
  };

  return useMutation(mutationFn, {
    onError: () => {
      console.log('Error getting AI');
    }
  });
}
